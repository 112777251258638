import SelectedBlog from '../components/selected-blog/selected-blog.component'

const SelectedBlogPage = ({ blog, allBlogs }) => {
  return (
    <div>
      <SelectedBlog blog={blog} allBlogs={allBlogs} />
    </div>
  )
}

export default SelectedBlogPage
