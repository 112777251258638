import React, { useContext } from 'react'

import { AppContext } from '../contexts/app.context'

import Blog from '../components/blog/blog.component'

const IndexPage = () => {
  const { blogData } = useContext(AppContext)

  return (
    <div>
      <Blog blog={blogData} />
    </div>
  )
}

export default IndexPage
