import React, { useState } from 'react'

import Calendar from '../../assets/calendar.svg'
import User from '../../assets/user.svg'
import Like from '../../assets/like.svg'

import { setBlogData, storage } from '../../utils/firebase.utils'
import {
  getDownloadURL,
  ref as storageRef,
  uploadBytes,
} from 'firebase/storage'
import styles from './selected-blog.module.scss'

const SelectedBlog = ({ blog, allBlogs }) => {
  const [imageUpload, setImageUpload] = useState(null)
  const [form, setForm] = useState({
    title: blog.title,
    content: blog.content,
    date: blog.date,
    image: blog.image,
    id: blog.id,
    path: blog.path,
  })

  const uploadFile = (blog) => {
    if (imageUpload === null) {
      // alert('Please select an image')
      return
    }
    const imageRef = storageRef(storage, `blog/${blog.id}`)

    // console.log(imageUpload, 'sa')

    uploadBytes(imageRef, imageUpload)
      .then((snapshot) => {
        console.log(snapshot, 'as')
        getDownloadURL(snapshot.ref)
          .then((url) => {
            console.log(url)
          })
          .catch((error) => {
            alert(error.message, 'error')
          })
      })
      .catch((error) => {
        alert(error.message)
      })
  }
  return (
    <div className={styles.blog}>
      <div className={styles.blog__content}>
        <div className={styles.blog__content__post}>
          <img
            src={`https://firebasestorage.googleapis.com/v0/b/reiki-gaia.appspot.com/o/blog%2F${form.id}?alt=media&token=59c021fd-99a3-4c66-aa47-15eecef2aad1`}
            alt="blog"
            className={styles.blog__content__post__image}
          />
          <input
            label="Image"
            placeholder="Dosya Seç"
            accept="image/png,image/jpeg"
            type="file"
            onChange={(e) => {
              setImageUpload(e.target.files[0])
            }}
          />
          <button onClick={() => uploadFile(blog)}>Upload</button>
          <h3>
            Mevcut Blog Başlığı: <span>{blog.title}</span>
          </h3>
          <h3>
            Blog Başlığını Değiştir{' '}
            <span>
              <input
                type="text"
                onChange={(e) => setForm({ ...form, title: e.target.value })}
                placeholder={blog.title}
              />
            </span>
          </h3>
          <div className={styles.blog__content__post__info}>
            <div className={styles.blog__content__post__info__item}>
              <img
                src={Calendar}
                alt="calendar"
                className={styles.blog__content__post__info__item__asset}
              />
              <p className={styles.blog__content__post__info__item__text}>
                {blog.date}
              </p>
            </div>
            <div className={styles.blog__content__post__info__item}>
              <img
                src={User}
                alt="user"
                className={styles.blog__content__post__info__item__asset}
              />
              <p className={styles.blog__content__post__info__item__text}>
                jAjA
              </p>
            </div>
            <div className={styles.blog__content__post__info__item}>
              <img
                src={Like}
                alt="like"
                className={styles.blog__content__post__info__item__asset}
              />
              <p className={styles.blog__content__post__info__item__text}>0</p>
            </div>
          </div>
          <p className={styles.content__parag}>{blog.content}</p>
          {/* <p>{blog.content_1}</p> */}
          <textarea
            type="text"
            onChange={(e) => setForm({ ...form, content: e.target.value })}
            placeholder="Text Alanini Degistir"
            style={{ width: '100%' }}
          />
          <button
            onClick={() => setBlogData(allBlogs, form)}
            className={styles.blog__content__post__btn}
          >
            Blog Ekle
          </button>
        </div>
      </div>
    </div>
  )
}

export default SelectedBlog
