import styles from './sidebar.module.scss'
import { Link } from 'react-router-dom'

const Sidebar = () => {
  return (
    <div className={styles.sidebar}>
      <Link to="/programs" className={styles.sidebar__item}>
        <h1>Program</h1>
      </Link>
      <Link to="/" className={styles.sidebar__item}>
        <h1>Blog</h1>
      </Link>
      <Link to="/address" className={styles.sidebar__item}>
        <h1>Adres</h1>
      </Link>
    </div>
  )
}

export default Sidebar
