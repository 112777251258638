import React, { useState, useContext } from 'react'
import { AppContext } from '../../contexts/app.context'
import styles from './address.module.scss'

import { setAddressesData } from '../../utils/firebase.utils'

const Address = () => {
  const { addresses } = useContext(AppContext)
  const [form, setForm] = useState({
    id: '',
    name: '',
    address: '',
    phone: '',
    email: '',
    googleMaps: '',
  })

  const changeSelectedAddress = (e) => {
    setForm({
      id: e.id,
      name: e.name,
      address: e.address,
      phone: e.phone,
      email: e.email,
      googleMaps: e.googleMaps,
    })
  }

  return (
    <div className={styles.blog}>
      {addresses.map((item) => (
        <div
          key={item.id}
          className={styles.blog__item}
          onClick={() => changeSelectedAddress(item)}
        >
          <h1>{item.name}</h1>
          <div className={styles.blog__form}>
            <div className={styles.blog__form__item}>
              <div className={styles.blog__form__item}>
                <label htmlFor="content">Adres</label>
                <input
                  type="text"
                  placeholder={item.address}
                  value={form.content}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      address: e.target.value,
                    })
                  }
                />
              </div>
              <div className={styles.blog__form__item}>
                <label htmlFor="content">Google Maps</label>
                <input
                  type="text"
                  placeholder={item.googleMaps}
                  value={form.content}
                  onChange={(e) =>
                    setForm({ ...form, googleMaps: e.target.value })
                  }
                />
              </div>
              <button
                type="submit"
                className={styles.blog__form__btn}
                onClick={() => setAddressesData(addresses, form)}
              >
                Adres Guncelle
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default Address
