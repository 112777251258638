import AddBlog from '../components/add-blog/add-blog.component'

const AddBlogPage = () => {
  return (
    <div>
      <AddBlog />
    </div>
  )
}

export default AddBlogPage
