import React, { useState, useContext } from 'react'
import Logo from './assets/logo.jpg'
import './App.css'

import AuthComponent from './components/auth/auth.component'
import { AppContext } from './contexts/app.context'
import { UserContext } from './contexts/user.context'
import { Route, Routes } from 'react-router-dom'

import { signInWithEmailAndPassword } from 'firebase/auth'
import {
  auth,
  setDataMenu,
  getBlogData2,
  getAddresses,
  programsDocumentList,
} from './utils/firebase.utils'

import Calendar from './assets/calendar.png'

import Blog from './components/blog/blog.component'
import IndexPage from './pages'
import SelectedBlogPage from './pages/selected-blog.page'
import AddBlogPage from './pages/add-blog.page'
import AddressPage from './pages/address.page'
import ProgramsPage from './pages/programs.page'
import Sidebar from './components/sidebar/sidebar.component'

function App() {
  const { blogData, addresses } = useContext(AppContext)
  const { user, setUser } = useContext(UserContext)
  const [loginMail, setLoginMail] = useState('')
  const [loginPassword, setLoginPassword] = useState('')
  const [authenticated, setAuthenticated] = useState(false)

  const handleSubmit2 = async (event) => {
    event.preventDefault()

    signInWithEmailAndPassword(auth, loginMail, loginPassword)
      .then((userCredential) => {
        const user = userCredential.user
        setAuthenticated(true)
        setUser(user)
      })
      .catch((error) => {
        setAuthenticated(false)
        window.alert('Gecersiz/Yetkisiz Kulanici')
      })
  }
  getBlogData2()
  getAddresses()
  programsDocumentList()
  // {authenticated === true ? (
  //   <Routes>
  //     <Route path="/" element={<IndexPage />} />
  //     {blogData.map((blog) => (
  //       <Route
  //         key={blog.id}
  //         path={blog.path}
  //         element={<SelectedBlogPage blog={blog} allBlogs={blogData} />}
  //       />
  //     ))}
  //     <Route path="/add-blog" element={<AddBlogPage />} />
  //     <Route path="/address" element={<AddressPage />} />
  //   </Routes>
  // ) : (
  //   <AuthComponent
  //     setLoginMail={setLoginMail}
  //     setLoginPassword={setLoginPassword}
  //     submit={handleSubmit2}
  //   />
  // )}
  return (
    <div className="App">
      {/* <a href="/">
        <img src={Logo} alt="logo" className="logo" />
      </a> */}
      <Sidebar />
      <Routes>
        <Route path="/" element={<IndexPage />} />
        {blogData.map((blog) => (
          <Route
            key={blog.id}
            path={blog.path}
            element={<SelectedBlogPage blog={blog} allBlogs={blogData} />}
          />
        ))}
        <Route path="/add-blog" element={<AddBlogPage />} />
        <Route
          path="/address"
          element={<AddressPage addresses={addresses} />}
        />
        <Route path="/programs" element={<ProgramsPage />} />
      </Routes>
    </div>
  )
}

export default App
