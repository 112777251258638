import { BLOG_ITEMS } from '../constants'

import { initializeApp } from 'firebase/app'
import {
  getFirestore,
  doc,
  setDoc,
  collection,
  getDocs,
  deleteField,
  updateDoc,
  getDoc,
  deleteDoc,
} from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import {
  getAuth,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
} from 'firebase/auth'

import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'

// const firebaseConfig = {
//   apiKey: 'AIzaSyAHLfPIgcrMFfDE4gfd5C_zMsoXzmk2nvU',
//   authDomain: 'reiki-gaia.firebaseapp.com',
//   projectId: 'reiki-gaia',
//   storageBucket: 'reiki-gaia.appspot.com',
//   messagingSenderId: '840803507017',
//   appId: '1:840803507017:web:13f5de23c8d6f7b25e9898',
//   measurementId: 'G-W80FKLH0BG',
// }

export const PROGRAMS = [
  { value: '2/3 Aralık 2023 Usui Tibet Reiki 1.&2. Seviyeleri', id: 0 },
  {
    value: '9/10 Aralık 2023 Usui Tibet Reiki 3.&4.(Master) Seviyeleri  ',
    id: 1,
  },
  { value: '16 Aralık 2023 Karuna – Prakriti Reiki Master Seviyesi  ', id: 2 },
  {
    value: '17 Aralık 2023 Sekhem – Seichim (Mısır) Reiki Master Seviyesi  ',
    id: 3,
  },
]

const firebaseConfig = {
  apiKey: 'AIzaSyAHLfPIgcrMFfDE4gfd5C_zMsoXzmk2nvU',
  authDomain: 'reiki-gaia.firebaseapp.com',
  projectId: 'reiki-gaia',
  storageBucket: 'reiki-gaia.appspot.com',
  messagingSenderId: '840803507017',
  appId: '1:840803507017:web:13f5de23c8d6f7b25e9898',
  measurementId: 'G-W80FKLH0BG',
}

const firebaseApp = initializeApp(firebaseConfig)

const googleProvider = new GoogleAuthProvider()

googleProvider.setCustomParameters({
  prompt: 'select_account',
})

export const auth = getAuth(firebaseApp)

const db = getFirestore(firebaseApp)
export const storage = getStorage(firebaseApp)

const blogRef = collection(db, 'blog')
const addressRef = collection(db, 'address')
const programsRef = collection(db, 'programs')

export const getBlogData = async () => {
  const blogData = await getDocs(blogRef)
  const data = blogData.docs.map((doc) => doc.data())
  return data
}

export const loginWithEmailAndPassword = async (email, password) => {
  if (!email || !password) return
  return await signInWithEmailAndPassword(auth, email, password)
}

export const logout = async () => {
  return await auth.signOut()
}

export const getBlogData2 = async () => {
  const blogData = await getDocs(blogRef)
  const data = blogData.docs.map((doc) => doc.data())
  return data
}

export const getAddresses = async () => {
  const addressData = await getDocs(addressRef)
  const data = addressData.docs.map((doc) => doc.data())
  return data
}

// export const setDataMenu = async () => {
//   BLOG_ITEMS.forEach(async (item) => {
//     await setDoc(doc(blogRef, `${item.id}`), {
//       ...item,
//     })
//   })
// }

// export const setDataMenu = async () => {
//   addresses.forEach(async (item) => {
//     await setDoc(doc(addressRef, `${item.id}`), {
//       ...item,
//     })
//   })
// }

// export const setDataMenu = async () => {
//   PROGRAMS.forEach(async (item) => {
//     await setDoc(doc(programsRef, `${item.id}`), {
//       ...item,
//     })
//   })
// }

export const setBlogData = async (data, form) => {
  data.map(async (item) => {
    if (item.id === form.id) {
      await setDoc(doc(blogRef, `${item.id}`), {
        ...form,
      })
    }
  })
}

export const programsDocumentList = async () => {
  const programCollectionRef = collection(db, 'programs')
  const programQuerySnapshot = await getDocs(programCollectionRef)
  const program = programQuerySnapshot.docs.map((doc) => doc.id)
  return program
}

export const getPrograms = async () => {
  const programData = await getDocs(programsRef)
  const data = programData.docs.map((doc) => doc.data())
  return data
}

export const updateSelectedProgram = async (data, form) => {
  data.map(async (item) => {
    if (item.id === form.id) {
      await setDoc(doc(programsRef, `${item.id}`), {
        ...form,
      })
    }
  })
}

export const deleteSelectedProgram = async (appData) => {
  const deletedRef = doc(db, 'programs', `${appData.city}`)
  await deleteDoc(deletedRef)
  window.location.reload()
}

export const addProgram = async (data) => {
  await setDoc(doc(programsRef, `${data.id}`), {
    ...data,
  })
  window.location.reload()
}

export const addProgramCity = async (data) => {
  const programsRef = doc(db, 'programs', data.city)
  await setDoc(programsRef, data)
  window.location.reload()
}

export const setAddressesData = async (dbData, appData) => {
  dbData.map(async (item) => {
    if (item.id === appData.id) {
      console.log('work ')
      await setDoc(doc(addressRef, `${item.id}`), {
        ...appData,
      })
    }
  })
}

export const addBlog = async (data) => {
  const detectLastBlogID = await getDocs(blogRef)
  const lastBlogID = detectLastBlogID.docs.length + 1
  await setDoc(doc(blogRef, `${lastBlogID}`), {
    ...data,
  })
}

firebase.initializeApp(firebaseConfig)

export default firebase
