import React, { useState, useContext } from 'react'
import { AppContext } from '../../contexts/app.context'
import { Dropdown } from 'flowbite-react'
import styles from './programs.module.scss'
import {
  deleteSelectedProgram,
  updateSelectedProgram,
  addProgram,
  addProgramCity,
} from '../../utils/firebase.utils'
const Programs = () => {
  const { programs, programsList } = useContext(AppContext)
  const [form, setForm] = useState({
    id: '',
    value: '',
    city: '',
  })
  const [newForm, setNewForm] = useState({
    id: '',
    value: '',
  })
  const [newCity, setNewCity] = useState({
    id: '',
    value: '',
    city: '',
  })

  const changeSelectedProgram = (e) => {
    setForm({
      id: e.id,
      value: e.value,
    })
  }
  console.log(programsList)
  return (
    <div className={styles.blog}>
      <div className={styles.blog__item}>
        {/* <h1>{item.name}</h1> */}
        <div className={styles.blog__form}>
          <div className={styles.blog__form__item}>
            <div className={styles.blog__form__item}>
              <label htmlFor="content">Egitim Ekle</label>

              <input
                type="text"
                placeholder="Egitim Ismi"
                onChange={(e) =>
                  setNewForm({
                    ...newForm,
                    value: e.target.value,
                    id: programs.length + 1,
                  })
                }
              />
            </div>
            <>
              Sehir Sec
              {programsList.map((item, i) => {
                console.log(item)
                return (
                  <label>
                    <input
                      type="radio"
                      name="city"
                      value={item}
                      onChange={(e) =>
                        setNewForm({
                          ...newForm,
                          city: e.target.value,
                        })
                      }
                    />
                    {item}
                  </label>
                )
              })}
            </>
            <button
              type="submit"
              className={styles.blog__form__btn}
              onClick={() => addProgram(newForm)}
            >
              Egitimi Ekle
            </button>
          </div>
        </div>
      </div>
      <div className={styles.blog__item}>
        {/* <h1>{item.name}</h1> */}
        <div className={styles.blog__form}>
          <div className={styles.blog__form__item}>
            <label htmlFor="content">Sehir Ekle</label>

            <input
              type="text"
              placeholder="Sehir Ismi"
              onChange={(e) => setNewCity({ ...newCity, city: e.target.value })}
            />
            <label htmlFor="content">Sehir Etkinlik Adi</label>

            <input
              type="text"
              placeholder="Etkinlik"
              onChange={(e) =>
                setNewCity({
                  ...newCity,
                  value: e.target.value,
                  id: programs.length + 1,
                })
              }
            />
          </div>
          <button
            type="submit"
            className={styles.blog__form__btn}
            onClick={() => addProgramCity(newCity)}
          >
            Sehir Ekle
          </button>
        </div>
      </div>
      {/* {programs.map((item, i) => (
        <div
          key={item.id}
          className={styles.blog__item}
          onClick={() => changeSelectedProgram(item)}
        >
          <h1>{item.name}</h1>
          <div className={styles.blog__form}>
            <div className={styles.blog__form__item}>
              <div className={styles.blog__form__item}>
                <label htmlFor="content">Egitim</label>

                <input
                  type="text"
                  placeholder={item.value}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      value: e.target.value,
                    })
                  }
                />
              </div>
              <button
                type="submit"
                className={styles.blog__form__btn}
                onClick={() => deleteSelectedProgram(item, programs)}
              >
                Egitimi Sil
              </button>
              <button
                type="submit"
                className={styles.blog__form__btn}
                onClick={() => updateSelectedProgram(programs, form)}
              >
                Egitimi Guncelle
              </button>
            </div>
          </div>
        </div>
      ))} */}
      {programsList.map((item, i) => (
        <div key={i} className={styles.blog__item}>
          <h1>{item}</h1>
          {programs.map((program) => {
            if (program.city === item) {
              return (
                <div className={styles.blog__form}>
                  <div className={styles.blog__form__item}>
                    <div className={styles.blog__form__item}>
                      <label htmlFor="content">Egitim</label>

                      <input
                        type="text"
                        placeholder={program.value}
                        onChange={(e) =>
                          setForm({
                            ...form,
                            value: e.target.value,
                          })
                        }
                      />
                    </div>
                    <button
                      type="submit"
                      className={styles.blog__form__btn}
                      onClick={() => deleteSelectedProgram(program, programs)}
                    >
                      Egitimi Sil
                    </button>
                    <button
                      type="submit"
                      className={styles.blog__form__btn}
                      onClick={() =>
                        updateSelectedProgram(programs, form, program.id)
                      }
                    >
                      Egitimi Guncelle
                    </button>
                  </div>
                </div>
              )
            }
          })}
        </div>
      ))}
    </div>
  )
}

export default Programs
