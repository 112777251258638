import Address from '../components/address/address.component.jsx'

const AddressPage = ({ addresses }) => {
  return (
    <div style={{ width: '100%' }}>
      <Address addresses={addresses} />
    </div>
  )
}

export default AddressPage
