import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import AddAsset from '../../assets/add.png'

import {
  changeImageData,
  setBlogData,
  // uploadFile,
} from '../../utils/firebase.utils'

import { storage, db, addContentParam } from '../../utils/firebase.utils'

import { BLOG_ITEMS } from './constants'

import styles from './blog.module.scss'
import {
  getDownloadURL,
  ref as storageRef,
  uploadBytes,
} from 'firebase/storage'
const Blog = ({ blog }) => {
  const [form, setForm] = useState({
    title: '',
    content: '',
    date: '',
    image: null,
    id: '',
    path: '',
  })
  const changeSelectedBlog = (e) => {
    setForm({
      title: e.title,
      content: e.content,
      date: e.date,
      image: e.image,
      id: e.id,
      path: e.path,
    })
  }

  return (
    <div className={styles.blog}>
      <div className={styles.blog__content}>
        <Link
          className={styles.blog__content__item}
          to="/add-blog"
          style={{ justifyContent: 'space-between' }}
        >
          <div className={styles.blog__content__item__link}>
            <img src={AddAsset} alt="blog" />
          </div>
          <div className={styles.blog__content__item__wrapper}>
            <div className={styles.blog__content__item__wrapper__link}>
              <h3>BLOG EKLEMEK ICIN TIKLAYIN</h3>
            </div>
          </div>
        </Link>
        {blog.map((blogItem, i) => (
          <Link
            className={styles.blog__content__item}
            key={i}
            onClick={() => changeSelectedBlog(blogItem)}
            to={blogItem.path}
          >
            <div
              to={blogItem.path}
              className={styles.blog__content__item__link}
            >
              <img
                src={`https://firebasestorage.googleapis.com/v0/b/reiki-gaia.appspot.com/o/blog%2F${blogItem.id}?alt=media&token=59c021fd-99a3-4c66-aa47-15eecef2aad1`}
                alt="blog"
                style={{ width: '40vh', height: '50vh' }}
              />
            </div>
            <div className={styles.blog__content__item__wrapper}>
              <div
                to={blogItem.path}
                className={styles.blog__content__item__wrapper__link}
              >
                <h3>
                  <span>{blogItem.title}</span>
                </h3>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  )
}

export default Blog
