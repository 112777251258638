import Programs from '../components/programs/programs.component'

const ProgramsPage = () => {
  return (
    <div style={{ width: '100%  ' }}>
      <Programs />
    </div>
  )
}

export default ProgramsPage
