import React, { createContext, useState, useEffect } from 'react'

import {
  getBlogData,
  getAddresses,
  getPrograms,
  programsDocumentList,
} from '../utils/firebase.utils'

export const AppContext = createContext({
  blogData: [],
  setBlogData: () => {},
  addresses: [],
  programs: [],
  programsList: [],
})

export const AppProvider = ({ children }) => {
  const [blogData, setBlogData] = useState([])
  const [addresses, setAddresses] = useState([])
  const [programs, setPrograms] = useState([])
  const [programsList, setProgramsList] = useState([])

  useEffect(() => {
    const fetchBlogData = async () => {
      const data = await getBlogData()
      const programData = await getPrograms()
      const addressData = await getAddresses()
      const programsList = await programsDocumentList()
      setPrograms(programData)
      setAddresses(addressData)
      setBlogData(data.sort((a, b) => a.id - b.id))
      setProgramsList(programsList)
    }
    fetchBlogData()
  }, [])
  const value = {
    blogData,
    setBlogData,
    addresses,
    programs,
    programsList,
  }

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}
