import React from 'react'
import styles from './auth.module.scss'

const AuthComponent = ({ submit, setLoginMail, setLoginPassword }) => {
  return (
    <div>
      <form onSubmit={submit} className={styles.form}>
        <input
          name="email"
          type="email"
          placeholder="Email"
          onChange={(e) => setLoginMail(e.target.value)}
          label="email"
          required
        />
        <input
          name="password"
          type="password"
          placeholder="Password"
          onChange={(e) => setLoginPassword(e.target.value)}
          label="password"
          required
        />
        <div className={styles.buttons}>
          <button type="submit" className={styles.button}>
            Sign in
          </button>
          
        </div>
      </form>
    </div>
  )
}

export default AuthComponent
