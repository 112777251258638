import React, { useState, useContext } from 'react'
import { AppContext } from '../../contexts/app.context'
import { addBlog, storage } from '../../utils/firebase.utils'
import {
  getDownloadURL,
  ref as storageRef,
  uploadBytes,
} from 'firebase/storage'

import styles from './add-blog.module.scss'
const AddBlog = () => {
  const { blogData } = useContext(AppContext)
  const [imageUpload, setImageUpload] = useState(null)
  const [addForm, setAddForm] = useState({
    title: '',
    content: '',
    date: new Date().toLocaleDateString(),
    image: null,
    path: '',
    id: '',
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    setAddForm({
      ...addForm,
    })
    addBlog(addForm)
    uploadFile()
    alert('Blog eklendi. Sayfayı yenileyiniz.')
  }
  const uploadFile = () => {
    if (imageUpload === null) {
      return
    }
    const imageRef = storageRef(storage, `blog/${blogData.length + 1}`)

    uploadBytes(imageRef, imageUpload)
      .then((snapshot) => {
        getDownloadURL(snapshot.ref)
          .then((url) => {
            console.log(url)
          })
          .catch((error) => {
            alert(error.message, 'error')
          })
      })
      .catch((error) => {
        alert(error.message)
      })
  }

  return (
    <div className={styles.blog}>
      <form onSubmit={handleSubmit} className={styles.blog__form}>
        <div className={styles.blog__form__item}>
          <label htmlFor="title">Blog Başlığı</label>
          <input
            type="text"
            placeholder="Title"
            value={addForm.title}
            onChange={(e) =>
              setAddForm({
                ...addForm,
                title: e.target.value,
                id: blogData.length + 1,
                path: `/blog/${blogData.length + 1}`,
              })
            }
          />
        </div>
        <div className={styles.blog__form__item}>
          <label htmlFor="content">Blog Content</label>
          <textarea
            type="text"
            placeholder="Text Area"
            value={addForm.content}
            onChange={(e) =>
              setAddForm({ ...addForm, content: e.target.value })
            }
          />
        </div>
        <div className={styles.blog__form__item}>
          <label htmlFor="image">Image</label>
          <input
            label="Image"
            placeholder="Choose image"
            accept="image/png,image/jpeg"
            type="file"
            onChange={(e) => {
              setImageUpload(e.target.files[0])
            }}
          />
          {/* <button onClick={() => uploadFile()}>Upload</button> */}
        </div>
        <button type="submit" className={styles.blog__form__btn}>
          Add Blog
        </button>
      </form>
    </div>
  )
}

export default AddBlog
